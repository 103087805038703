import React, { Component } from 'react';
import Highlight from './Highlight';

import 'highlight.js/styles/default.css';

export default class ViewerHLJS extends Component {
  state = {
    text: null,
  };
  componentDidMount() {
    fetch(this.props.link)
      .then(res => res.text())
      .then(text => this.setState({ text }));
  }
  render() {
    const { text } = this.state;
    const { extension, wrapperProps } = this.props;
    return (
      text && (
        <div className="hljs-pre" {...wrapperProps}>
          <Highlight
            onClick={e => e.stopPropagation()}
            style={{ width: '100%', height: '100%', fontFamily: 'monospace' }}
            className={extension}
          >
            {text}
          </Highlight>
        </div>
      )
    );
  }
}
