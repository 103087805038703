import React, { Component } from 'react';

class ViewerPDF extends Component {
  state = { blobUrl: '' };
  componentDidMount() {
    fetch(this.props.link)
      .then(response => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Network response was not ok.');
      })
      .then(octetBlob => {
        const pdfBlob = new Blob([octetBlob], { type: 'application/pdf' });
        var blobUrl = URL.createObjectURL(pdfBlob);
        this.setState({ blobUrl });
      })
      .catch(error =>
        console.log(
          'There has been a problem with your fetch operation: ',
          error.message
        )
      );
  }
  render() {
    const { link, id, wrapperProps } = this.props;
    const { blobUrl } = this.state;
    if (!blobUrl) {
      return null;
    }
    return (
      <iframe
        {...wrapperProps}
        onClick={e => e.stopPropagation()}
        src={blobUrl}
        title={id}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      >
        <p>
          Your browser does not support PDFs.
          <a href={link}>Download the PDF</a>.
        </p>
      </iframe>
    );
  }
}

export default ViewerPDF;
