import React from 'react';

import CenterChild from './CenterChild';

export default ({ link, content_type, wrapperProps }) => (
  <CenterChild {...wrapperProps}>
    <video
      controls
      style={{ maxWidth: '100%' }}
      onClick={e => e.stopPropagation()}
    >
      <source src={link} type={content_type} />
      Your browser does not support the video element.
    </video>
  </CenterChild>
);
