import ViewerHLJS from './ViewerHLJS';
import ViewerAudio from './ViewerAudio';
import ViewerVideo from './ViewerVideo';
import ViewerImage from './ViewerImage';
import ViewerPDF from './ViewerPDF';
import ViewerConvert from './ViewerConvert';

export default {
  aac: ViewerAudio,
  aif: ViewerAudio,
  aifc: ViewerAudio,
  aiff: ViewerAudio,
  amr: ViewerAudio,
  au: ViewerAudio,
  flac: ViewerAudio,
  m3u: ViewerAudio,
  m4a: ViewerAudio,
  mid: ViewerAudio,
  mp3: ViewerAudio,
  ra: ViewerAudio,
  wav: ViewerAudio,
  wma: ViewerAudio,
  wpl: ViewerAudio,
  as: ViewerHLJS,
  as3: ViewerHLJS,
  asm: ViewerHLJS,
  aspx: ViewerHLJS,
  cpp: ViewerHLJS,
  bat: ViewerHLJS,
  c: ViewerHLJS,
  cc: ViewerHLJS,
  cmake: ViewerHLJS,
  cs: ViewerHLJS,
  css: ViewerHLJS,
  cxx: ViewerHLJS,
  db: ViewerHLJS,
  diff: ViewerHLJS,
  erb: ViewerHLJS,
  groovy: ViewerHLJS,
  h: ViewerHLJS,
  haml: ViewerHLJS,
  hh: ViewerHLJS,
  htm: ViewerHLJS,
  html: ViewerHLJS,
  java: ViewerHLJS,
  js: ViewerHLJS,
  less: ViewerHLJS,
  m: ViewerHLJS,
  make: ViewerHLJS,
  md: ViewerHLJS,
  ml: ViewerHLJS,
  mm: ViewerHLJS,
  php: ViewerHLJS,
  pl: ViewerHLJS,
  plist: ViewerHLJS,
  properties: ViewerHLJS,
  py: ViewerHLJS,
  rb: ViewerHLJS,
  sass: ViewerHLJS,
  scala: ViewerHLJS,
  script: ViewerHLJS,
  scm: ViewerHLJS,
  sml: ViewerHLJS,
  sql: ViewerHLJS,
  sh: ViewerHLJS,
  wabba: ViewerHLJS,
  yaml: ViewerHLJS,
  txt: ViewerHLJS,
  vi: ViewerHLJS,
  vim: ViewerHLJS,
  webdoc: ViewerHLJS,
  bmp: ViewerImage,
  gif: ViewerImage,
  gdraw: ViewerImage,
  jpeg: ViewerImage,
  jpg: ViewerImage,
  png: ViewerImage,
  ps: ViewerImage,
  svs: ViewerImage,
  svg: ViewerImage,
  tif: ViewerImage,
  tiff: ViewerImage,
  '3g2': ViewerVideo,
  '3gp': ViewerVideo,
  avi: ViewerVideo,
  flv: ViewerVideo,
  m2v: ViewerVideo,
  m2ts: ViewerVideo,
  m4v: ViewerVideo,
  mkv: ViewerVideo,
  mov: ViewerVideo,
  mp4: ViewerVideo,
  mpeg: ViewerVideo,
  mpg: ViewerVideo,
  ogg: ViewerVideo,
  mts: ViewerVideo,
  qt: ViewerVideo,
  wmv: ViewerVideo,
  pdf: ViewerPDF,
  xls: ViewerConvert,
  xlsx: ViewerConvert,
  xlsm: ViewerConvert,
  eps: ViewerConvert,
  key: ViewerConvert,
  numbers: ViewerConvert,
  pages: ViewerConvert,
  odp: ViewerConvert,
  otp: ViewerConvert,
  pot: ViewerConvert,
  potx: ViewerConvert,
  ppt: ViewerConvert,
  pptx: ViewerConvert,
  pptm: ViewerConvert,
  psd: ViewerConvert,
  ods: ViewerConvert,
  xlt: ViewerConvert,
  xltx: ViewerConvert,
  docx: ViewerConvert,
  doc: ViewerConvert,
  docm: ViewerConvert,
};
