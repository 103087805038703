import React from 'react';

import CenterChild from './CenterChild';

export default ({ link, content_type, wrapperProps }) => (
  <CenterChild {...wrapperProps}>
    <audio controls onClick={e => e.stopPropagation()}>
      <source src={link} type={content_type} />
      Your browser does not support the audio element.
    </audio>
  </CenterChild>
);
