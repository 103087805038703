import React from 'react';

import CenterChild from './CenterChild';

export default ({ link, metadata: { name, document_type }, wrapperProps }) => (
  <CenterChild {...wrapperProps}>
    <img
      src={link}
      alt={name + '_' + document_type}
      style={{ maxWidth: '100%', maxHeight: '100%' }}
      onClick={e => e.stopPropagation()}
    />
  </CenterChild>
);
