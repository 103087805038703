import React from "react";
import CenterChild from "./CenterChild";

export default ({link, id, extension, wrapperProps}) => {
  const encodedFileUrl = encodeURIComponent(link);
  const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
    encodedFileUrl
  )}`;
  return (
    <CenterChild {...wrapperProps}>
      <iframe
        onClick={e => e.stopPropagation()}
        src={viewerUrl}
        title={id}
        width="100%"
        height="100%"
        frameborder="0"
        style={{border: "none"}}
      >
        <p>
          Your browser does not support PDFs.
          <a href={link}>Download the PDF</a>.
        </p>
      </iframe>
    </CenterChild>
  );
};
